// Styles SCSS
import "../sass/app.scss";
//import autocomplete from './utils/autocomplete';

// SVG polyfiil
import "svgxuse";

import Accordion from "accordion-js";

// LazyLoad
import lazyLoadInit from "./lazyload-init";
lazyLoadInit();

// pushbar
import Pushbar from "./utils/pushbar";

if (jQuery("#submenu > li").hasClass("current_page_item")) {
  jQuery("#subsubmenu").appendTo("#menu-lvl3 .current_page_item");
}

if (document.querySelector(".accordion-container") !== null) {
  const accordions = document.querySelectorAll(".accordion-container");
  // node list to array
  const accordionsArray = Array.from(accordions);
  accordionsArray.forEach((accordion) => {
    // get index of accordion
    const index = accordionsArray.indexOf(accordion);
    new Accordion(".accordion_" + index, {
      duration: 400,
      showMultiple: false,
    });
  });
}

const pushbar = new Pushbar({
  blur: true,
  overlay: true,
});
